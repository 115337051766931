// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-app-import-index-js": () => import("/opt/build/repo/src/pages/ios-app-import/index.js" /* webpackChunkName: "component---src-pages-ios-app-import-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/build/repo/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */)
}

